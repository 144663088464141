.contact__page__wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
.contact__info{
    display: flex;
    flex-direction: column;
}
.contact__details{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 0.4rem;
}

.contact__image{
    width: 100%;
    height: 50%;
    object-fit: cover;
}
.contact__form{
    display: flex;
    flex-direction: column;
}
.contact__input__field{
    padding: .5rem;
    outline: none;
    border: 1px solid #e6e6e6;
    margin-bottom: 1rem;
}
.contact__form__label{
    font-size: large;
}
.contact__form__map{
    margin-top: 2rem;
}
.contact__loader{
    margin-inline: auto;
    margin-top: 2rem;
}
@media only screen and (max-width:600px){
    .contact__page__wrapper{
        display: flex;
       flex-direction: column;
        gap: 2rem;
    }
}
