.our__message__wrapper{
    display: flex;
    flex-direction: column;

    div:nth-child(2n+1) {
        div{
            direction: rtl;
        }
    }
}
.about__categories__wrapper{
    margin-top: 2rem;
}
.about__categories__info{
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;
}
@media only screen and (max-width:600px){
    .about__categories__info{
        grid-template-columns: 1fr;
    }
}