.principles__grid{
    margin-top: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(3,1fr);
}

@media only screen and (max-width: 600px) {
    .principles__grid{
        margin-top: 2rem;
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr;
    }
 }