.recruitment__process{
    margin-block: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
@media only screen and (max-width:600px){
    .recruitment__process{
        grid-template-columns:  1fr;
    }
}