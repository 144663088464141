.heading__white{
    color: white !important;
}
.heading{
    color: #444444;
}
.heading__uppercase{
    text-transform: uppercase;   
}
.pagetop__heading{
    position: absolute;
    color: white;
    top: 25%;
    left: 19%;
}
@media only screen and (max-width:600px){
    .pagetop__heading{
        position: absolute;
        color: white;
        top: 25%;
        left: 5%;
    }
}