.homePage__image__wrapper{
    position: relative;
}
.homePage__image{
    width: 100%;
    height:45rem;
    object-fit: cover; 
    filter: saturate(2)
}
.homePage__image__content{
    position: absolute;
    top: 15%;
    left: 19%;
    color: white;
    display: flex;
    gap: 8rem;
}
.homePage__image__rightContent{
    /* Glass Effect */
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
  
    /* Glass effect end */

    width: 30%;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
}

@media only screen and (max-width: 1150px) {
    .homePage__image__rightContent{
        display: none;
    }
}
@media only screen and (max-width: 600px) {
   .homePage__image{
        width: 100%;
        height:25rem;
        object-fit: cover; 
        filter: saturate(2)
    }
    .homePage__image__content{
       top: 25%;
       left: 5%;
       color: white; 
       font-size: small;
    }
}