button{
    padding: 0.5rem 1rem;
    cursor: pointer;  
    font-weight: 600;
    outline: none;
}
.header__button{
    border: none;
    background-color: transparent;
    color: white;
}
.header__button:hover{
    border-bottom: 1px solid white;
}
.homePage__readMore__button{
    color: white;
    border: 1px solid white;
    background-color: transparent;
    border-radius: 0.5rem;
    width: 100%;
}
.homePage__readMore__button:hover{
    background-color: white;
    color: var(--primary-color);
}
.licence__view__button{
    background-color: #24A6CE;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    border: none;
}
.send__email__button{
    background-color: var(--primary-color);
    color: white;
    border-radius: 0.5rem;
    width: 50%;
    
}