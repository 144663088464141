.children__wrapper{
    max-width: 1170px; 
    margin-inline: auto;
}

.pages__wrapper{
    margin-top: -8rem;
    z-index: 99 !important;
    background-color: white;
    position: relative;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    padding-block: 3rem;
}

@media only screen and (max-width: 600px) {
    .children__wrapper{
        max-width: 100%;
        margin-inline: auto;
    }
}